@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');
@import url('https://fonts.googleapis.com/css?family=Oswald');

$headings-font-family: Oswald;
$font-family-base: Nunito Sans;

// text color
// $body-color: #555555;
$body-color: #333333;

$primary: #cddeb9;
$secondary: #fff9a7;

$link-color: #80A25C;

$navbar-nav-link-padding-x: 1rem;





@import "~bootstrap/scss/bootstrap";

.text-larger {
  font-size: $font-size-base * 1.15;
}

// usato nei Badge in BusinessHome
.text-normal {
  font-size: $font-size-base;
}

.joppys-banner {
  // font-family: Oswald !important;
  line-height: $headings-line-height * 0.75 !important;
  font-weight: 700 !important;
}

#joppys-footer {
  /* bootstrap class: mt-auto footer bg-light  */
  margin-top: auto !important;
  // --bs-bg-opacity: 1;
  // background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

// centra i pulsanti quando la navbar è collapsed
.navbar .navbar-collapse {
  text-align: center;
}

// used for scroll restoration
html {
  scroll-behavior: auto !important;

}

// strange margin at the end
#root {
  display: flex !important;
  flex-direction: column !important;
  // height: 100% !important;
}


/* remove arrows in input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}
/* end remove arrows in input type number */
